import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../axiosConfig";
import { IServiceResponce, ITask } from "./store.types";
import exp from "constants";

interface ITasksState {
  notifications: {
    data: any[] | null;
    pagination: {
      skip: number;
      top: number;
      total: number;
      unread: number;
      read: number;
    };
    isLoading: boolean;
    hasError: boolean;
  };
}

export const NAME = "notifications";

const initialState: ITasksState = {
  notifications: {
    data: null,
    pagination: { skip: 0, top: 15, total: 0, unread: 0, read: 0 },
    isLoading: false,
    hasError: false,
  },
};

export const getNotifications = createAsyncThunk(
  `${NAME}/getOutstandingTasks`,
  async (args: any, thunkAPI) => {
    const { pagination } = args;
    try {
      const response = await axiosInstance.get(
        `notifications?top=${pagination}&skip=0`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postReadNotification = createAsyncThunk(
  `${NAME}/postReadNotification`,
  async (args: any, thunkAPI) => {
    const { body } = args;
    try {
      const response = await axiosInstance.post(
        `notifications/mark-as-read`,
        body
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const reducers = {};

export const notificationsSlice = createSlice({
  name: NAME,
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      const clone = { ...state.notifications };
      clone.isLoading = true;
      clone.data = null;
      clone.hasError = false;
      state.notifications = clone;
      //
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      console.log("action.payload", action.payload);
      const clone = { ...state.notifications };
      clone.isLoading = false;
      clone.data = action.payload.data.results as any[];
      clone.pagination = action.payload.data.pagination as IPageination;
      clone.hasError = false;
      state.notifications = clone;
      //
    });
    builder.addCase(getNotifications.rejected, (state) => {
      const clone = { ...state.notifications };
      clone.isLoading = false;
      clone.hasError = true;
      state.notifications = clone;
      //
    });
    builder.addCase(postReadNotification.pending, (state) => {
      state.isLoading = true;
      state.notifications = null;
      state.hasError = false;
      //
    });
    builder.addCase(postReadNotification.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload.data.results as ITask[];
      state.hasError = false;
      //
    });
    builder.addCase(postReadNotification.rejected, (state) => {
      state.isLoading = false;
      state.notifications = null;
      state.hasError = true;
      //
    });
  },
});

export const selectIsLoading = (state) => state[NAME].notifications.isLoading;
export const selectNotifications = (state) => state[NAME].notifications.data;
export const selectPagination = (state) => state[NAME].notifications.pagination;
export const selectError = (state) => state[NAME].notifications.hasError;
export const selectUnreadCount = (state) =>
  state[NAME].notifications.pagination.unread;
export const selectReadCount = (state) =>
  state[NAME].notifications.pagination.read;
export const selectTotalCount = (state) =>
  state[NAME].notifications.pagination.total;

//export const {} = notificationsSlice.actions;

export default notificationsSlice.reducer;
