export const SET_SUMMARY = "SET_SUMMARY";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CONFIFENTIALITY_LEVEL = "SET_CONFIFENTIALITY_LEVEL";

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_SUMMARY: {
      return {
        ...state,
        summary: action.payload,
      };
    }
    case SET_CATEGORY: {
      return {
        ...state,
        categoryId: action.payload.id,
        categoryName: action.payload.name,
      };
    }
    case SET_CONFIFENTIALITY_LEVEL: {
      return {
        ...state,
        documentPrivacyLevels: action.payload,
      };
    }
  }
};
