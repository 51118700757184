import axios from "axios";

import { loginRequest, msalInstance } from "./authConfig";

const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const accounts = msalInstance.getAllAccounts();

      if (accounts.length === 0) {
        console.warn("No user account found. Redirecting to login.");
        await msalInstance.loginRedirect(loginRequest);
        return Promise.reject("User not logged in. Redirecting to login.");
      }

      const silentRequest = {
        ...loginRequest,
        account: accounts[0],
      };

      const tokenResponse = await msalInstance.acquireTokenSilent(
        silentRequest
      );
      config.headers["Authorization"] = `Bearer ${tokenResponse.idToken}`;
    } catch (error) {
      if (
        error.errorCode === "login_required" ||
        error.errorCode === "monitor_window_timeout"
      ) {
        msalInstance.acquireTokenRedirect(loginRequest);
      } else {
        console.error("Error acquiring token silently:", error);
        throw error;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
