import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";

const MSALUnauthenticatedTemplate = () => {
  const { instance } = useMsal();

  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: 9999,
        display: "flex",
        padding: 0,
        margin: 0,
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: "20px",
          maxWidth: "400px",
        }}
      >
        <Typography variant="body1">
          Please log on with your ADL credentials.
        </Typography>
        <Button
          variant="contained"
          onClick={() => instance.loginRedirect()}
          disableElevation
        >
          Sign In
        </Button>
      </Box>
    </Box>
  );
};

export default MSALUnauthenticatedTemplate;
