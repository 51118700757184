import React, { useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import MSALUnauthenticatedTemplate from "@/templates/MSALUnauthenticatedTemplate";

import { loginRequest } from "@/authConfig";
import axiosInstance from "../../axiosConfig";

export const MSALAuthentication = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (account && inProgress === InteractionStatus.None) {
      instance
        ?.acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((result) => {
          sessionStorage.setItem("msalAccessToken", result.idToken);
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${result.idToken}`;
        })
        .catch((error) => {
          if (
            error.errorCode === "login_required" ||
            error.errorCode === "monitor_window_timeout" ||
            error.errorMessage === InteractionRequiredAuthError
          ) {
            instance.acquireTokenRedirect(loginRequest);
          } else {
            console.error("Error acquiring token silently:", error);
            throw error;
          }
        });
    }
  }, [instance, account, inProgress]);

  return (
    <>
      <AuthenticatedTemplate>
        <>{children}</>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <MSALUnauthenticatedTemplate />
      </UnauthenticatedTemplate>
    </>
  );
};

export default MSALAuthentication;
