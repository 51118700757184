import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import userReducer from "./userSlice";
import authReducer from "./authSlice";
import casesReducer from "./casesSlice";
import tasksReducer from "./tasksSlice";
import notificationsReducer from "./notificationsSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    auth: authReducer,
    cases: casesReducer,
    tasks: tasksReducer,
    notifications: notificationsReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = {
  app: ReturnType<typeof appReducer>;
  user: ReturnType<typeof userReducer>;
  auth: ReturnType<typeof authReducer>;
  cases: ReturnType<typeof casesReducer>;
  tasks: ReturnType<typeof tasksReducer>;
  notifications: ReturnType<typeof notificationsReducer>;
};

export type AppDispatch = typeof store.dispatch;
