import React, { useEffect, useReducer } from "react";
import { Typography } from "@mui/material";

import {
  selectSelectedCase,
  fetchCaseDetailsById,
  selectProfileState,
  deleteDocumentbyId,
  setSelectedCaseActivites,
  setSelectedDocument,
  selectSelectedCaseStatus,
  selectUploadComplete,
  selectSelectedCaseSummary,
  selectSelectedCasePrivicyLevel,
  setCaseEditsToSave,
  selectEditsToSave,
  selectAdditionalMappings,
  selectIsSaving,
  selectSavedSuccess,
} from "@/redux/casesSlice";
import { useAppSelector, useAppDispatch } from "@/hooks";
import FileUploader from "../FileUploader";
import CasePrivacyRestrictions from "../CasePrivacyRestrictions";

import * as Styled from "./CaseProfileWrapper.styles";
import CaseDetails from "./CaseDetails";
import CaseRequirementsStatus from "./CaseRequirementsStatus";
import DocumentList from "./DocumentList";
import FileDeleteModal from "./FileDeleteModal";
import Activities from "@/components/Activities";
import { IDocument } from "@/redux/store.types";
import { EditableSummary, RenderIf } from "@adl/smart-ui-library";
import { POLLING_INTERVAL } from "../../constants";
import {
  reducer,
  SET_PRIVACY_LEVEL,
  SET_SUMMARY,
  SET_ADDITIONAL_MAPPINGS,
} from "./CaseProfileWrapper.reducer";
import _ from "lodash";
import { areObjectsEqualUnordered } from "../../utils";
import {
  setAdditionalMappings,
  setCasePrivacyLevel,
  setCaseSummary,
} from "../../redux/casesSlice";
import CaseProfile from "./CaseProfile.view";

const CaseProfileWrapper = () => {
  const selectedCase = useAppSelector(selectSelectedCase);
  const caseSummary = useAppSelector(selectSelectedCaseSummary);
  const casePrivacylevel = useAppSelector(selectSelectedCasePrivicyLevel);
  const editsToSave = useAppSelector(selectEditsToSave);
  const additionalMappings = useAppSelector(selectAdditionalMappings);

  const isSaving = useAppSelector(selectIsSaving);
  const saveComplete = useAppSelector(selectSavedSuccess);
  const appDispatch = useAppDispatch();

  const [state, dispatch] = useReducer(reducer, {
    summary: caseSummary,
    privacyLevel: casePrivacylevel,
    additionalMappings: additionalMappings,
  });

  useEffect(() => {
    dispatch({ type: SET_SUMMARY, payload: caseSummary });
    dispatch({ type: SET_PRIVACY_LEVEL, payload: casePrivacylevel });
    dispatch({ type: SET_ADDITIONAL_MAPPINGS, payload: additionalMappings });
  }, [caseSummary, casePrivacylevel, additionalMappings]);

  useEffect(() => {
    const hasChanged =
      state.summary !== caseSummary ||
      state.privacyLevel !== casePrivacylevel ||
      !areObjectsEqualUnordered(state.additionalMappings, additionalMappings);

    if (hasChanged) {
      appDispatch(setCaseEditsToSave(state));
    } else {
      appDispatch(setCaseEditsToSave(null));
    }
  }, [state]);

  useEffect(() => {
    if (saveComplete) {
      appDispatch(setCasePrivacyLevel(editsToSave?.privacyLevel));
      //appDispatch(setCaseSummary(editsToSave?.summary));
      //appDispatch(setAdditionalMappings(editsToSave?.additionalMappings));
      //appDispatch(setCaseEditsToSave(null));
    }
  }, [isSaving, saveComplete]);

  const { ShortName, DeliveryEngagementId } = selectedCase;

  return (
    <>
      <Styled.CaseDetailsContent id="case-details-content">
        <Styled.CaseDetailsContentLeft id="case-details-content-left">
          <Typography variant="h5">{ShortName}</Typography>
          <CasePrivacyRestrictions
            privacyLevel={state.privacyLevel}
            onUpdate={(level) =>
              dispatch({ type: SET_PRIVACY_LEVEL, payload: level })
            }
          />
          <EditableSummary
            onUpdate={(text) => {
              if (text === state.summary) return;
              dispatch({ type: SET_SUMMARY, payload: text });
            }}
            text={state.summary}
          />
          <CaseDetails
            onUpdate={(value) => {
              dispatch({ type: SET_ADDITIONAL_MAPPINGS, payload: value });
            }}
            details={state.additionalMappings}
          />
          <CaseProfile allowPolling={Boolean(!editsToSave)} />
        </Styled.CaseDetailsContentLeft>
        <Styled.CaseDetailsContentRight id="case-details-content-right">
          <FileUploader
            id={DeliveryEngagementId}
            email={"kamba.marina@adlittle.com"}
            uploadComplete={() => null}
          />
          <Activities />
        </Styled.CaseDetailsContentRight>
      </Styled.CaseDetailsContent>
    </>
  );
};

export default CaseProfileWrapper;
