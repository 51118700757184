import React from "react";
import { Breadcrumbs, IconButton, Typography, Button } from "@mui/material";

import DefaultTemplate from "@/templates/DefaultTemplate/DefaultTemplate";
import { PageHeader, PageContent } from "@adl/smart-ui-library";
import { useAppDispatch } from "@/hooks";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import { setSelectedTasks } from "@/redux/tasksSlice";
import { setSelectedCase, setSelectedDocument } from "@/redux/casesSlice";
import { useMsal } from "@azure/msal-react";
import Notifications from "@/components/Notifications";

import MyTasksWrapper from "../../components/MyTasksWrapper";
import VaultIcon from "@/components/VaultIcon";
import VaultTextLogo from "@/components/VaultTextLogo";

const Tasks = () => {
  const appDispatch = useAppDispatch();

  const navigateToHome = () => {
    appDispatch(setSelectedTasks(false));
    appDispatch(setSelectedCase(null));
    appDispatch(setSelectedDocument(null));
  };
  const { instance } = useMsal();

  const onLogout = () => {
    instance.logout();
  };

  const onTaskBtnClick = () => {
    appDispatch(setSelectedTasks([]));
  };

  return (
    <DefaultTemplate paddingTop={200}>
      <PageHeader
        logo={<VaultIcon width={40} height={40} handleClick={navigateToHome} />}
        navigation={
          <>
            <Notifications />
            <Button onClick={onTaskBtnClick}>Tasks</Button>
            <Button onClick={onLogout}>Logout</Button>
          </>
        }
        subHeader={
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <IconButton onClick={navigateToHome}>
              <HomeOutlinedIcon />
            </IconButton>
            <Typography color="text.primary">Tasks</Typography>
          </Breadcrumbs>
        }
      >
        <VaultTextLogo />
      </PageHeader>

      <PageContent>
        <MyTasksWrapper />
      </PageContent>
    </DefaultTemplate>
  );
};

export default Tasks;
