import React, { useEffect } from "react";
import { RenderIf } from "@adl/smart-ui-library";
import CaseRequirementsStatus from "./CaseRequirementsStatus";
import DocumentList from "./DocumentList";
import FileDeleteModal from "./FileDeleteModal";
import {
  selectSelectedCase,
  deleteDocumentbyId,
  fetchCaseDetailsById,
  setSelectedCaseActivites,
  setSelectedDocument,
  selectSelectedCaseSummary,
  selectSelectedCasePrivicyLevel,
  setCaseEditsToSave,
  selectEditsToSave,
  selectAdditionalMappings,
  selectIsSaving,
  selectSavedSuccess,
  selectSelectedCaseStatus,
  selectProfileState,
  selectUploadComplete,
} from "../../redux/casesSlice";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { IDocument } from "../../redux/store.types";
import { POLLING_INTERVAL } from "../../constants";

type Props = {
  allowPolling?: boolean;
};

const CaseProfile = ({ allowPolling = true }: Props) => {
  const appDispatch = useAppDispatch();
  const selectedCase = useAppSelector(selectSelectedCase);
  const caseDocuments = useAppSelector(selectProfileState);
  const statuses = useAppSelector(selectSelectedCaseStatus);
  const isFileUploadComplete = useAppSelector(selectUploadComplete);
  const [isRefresh, setIsRefresh] = React.useState<boolean>(false);

  const { DeliveryEngagementId } = selectedCase;

  const [skip, setSkip] = React.useState(0);
  const [toDelete, setToDelete] = React.useState<number | string | null>(null);

  const refreshData = async (refresh?: boolean) => {
    setIsRefresh(Boolean(refresh));
    appDispatch(
      fetchCaseDetailsById({
        deliveryEngagementId: DeliveryEngagementId,
        top: 10,
        skip: skip,
        hideLoading: refresh,
      })
    );
  };

  const onUpdatePagination = (skip: number) => {
    setSkip(skip);
  };

  const onSelectDocument = (data: IDocument) => {
    appDispatch(setSelectedCaseActivites(null));
    appDispatch(setSelectedDocument(data));
  };

  const onDeleteFile = async (documentId) => {
    try {
      await appDispatch(
        deleteDocumentbyId({
          deliveryEngagementId: DeliveryEngagementId,
          documentId,
        })
      );
      setToDelete(null);
      refreshData();
    } catch (error) {
      console.error("Failed to delete document:", error);
    }
  };

  useEffect(() => {
    allowPolling && refreshData(false);

    const polling = setInterval(() => {
      allowPolling && refreshData(true);
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(polling);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, allowPolling]);

  useEffect(() => {
    isFileUploadComplete && refreshData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileUploadComplete]);

  return (
    <>
      <CaseRequirementsStatus statuses={statuses} isRefresh={isRefresh} />
      <DocumentList
        {...caseDocuments}
        onUpdate={onUpdatePagination}
        onDeleteFile={(id) => setToDelete(id)}
        onSelectDocument={onSelectDocument}
        isRefresh={isRefresh}
      />

      <RenderIf isTrue={toDelete !== null}>
        <FileDeleteModal
          onCancel={() => setToDelete(null)}
          onClose={() => onDeleteFile(toDelete)}
        >
          Are you sure you want to delete?
        </FileDeleteModal>
      </RenderIf>
    </>
  );
};

export default CaseProfile;
